/* eslint-disable fp/no-let */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import ChevronIcon from '@scandipwa/scandipwa/src/component/ChevronIcon';
import { Directions } from '@scandipwa/scandipwa/src/component/ChevronIcon/ChevronIcon.config';
// import CloseIcon from '@scandipwa/scandipwa/src/component/CloseIcon';
import TextPlaceholder from '@scandipwa/scandipwa/src/component/TextPlaceholder';
import { TextPlaceHolderLength } from '@scandipwa/scandipwa/src/component/TextPlaceholder/TextPlaceholder.config';
import { ReactElement } from 'react';

import {
    ExpandableContentComponent as SourceExpandableContentComponent,
} from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.style';

/** @namespace Inov8/Component/ExpandableContent/Component */
export class ExpandableContentComponent extends SourceExpandableContentComponent {
    renderButton(): ReactElement {
        const { isContentExpanded } = this.state;
        const { heading, mix } = this.props;

        return (
            <div
              role="button"
              tabIndex={ 0 }
              block="ExpandableContent"
              elem="Button"
              mods={ { isContentExpanded } }
              mix={ { ...mix, elem: 'ExpandableContentButton' } }
              onClick={ this.toggleExpand }
              onKeyDown={ this.toggleExpand }
            >
                <div
                  block="ExpandableContent"
                  elem="Heading"
                  mix={ { ...mix, elem: 'ExpandableContentHeading' } }
                >
                    { typeof heading === 'string' ? (
                        <TextPlaceholder content={ heading } length={ TextPlaceHolderLength.MEDIUM } />
                    ) : (
                        heading
                    ) }
                </div>
                { this.renderButtonIcon() }
            </div>
        );
    }

    renderButtonIcon(): ReactElement | null {
        const { isContentExpanded } = this.state;
        const { isArrow } = this.props;

        if (isArrow) {
            return <ChevronIcon direction={ isContentExpanded ? Directions.TOP : Directions.BOTTOM } />;
        }

        return this.renderTogglePlusMinus();
    }

    renderTogglePlusMinus(): ReactElement {
        const { isContentExpanded } = this.state;

        const openCloseClass = !isContentExpanded ? 'IconClose' : 'IconOpen';

        return (
            <div block="ExpandableContent-AddIcon" elem={ openCloseClass }>
                <p block="ExpandableContent-AddIcon" elem="Text">
                    { isContentExpanded ? 'Close' : 'Open' }
                    { ' ' }
                    Cart Summary
                </p>
            </div>
        );
    }

    renderContent(): ReactElement {
        const { children, heading, mix } = this.props;
        const { isContentExpanded } = this.state;
        const mods = { isContentExpanded };
        let elemType = '';

        switch (heading) {
        case 'Shoe Size':
            elemType = 'Content-ShoeSize';
            break;
        case 'Size':
            elemType = 'Content-Flex';
            break;
        case 'Shoe Width':
            elemType = 'Content-WidthSelect';
            break;
        default:
            elemType = 'Content';
        }

        return (
            <div
              block="ExpandableContent"
              elem={ elemType }
              mods={ mods }
              mix={ { ...mix, elem: 'ExpandableContentContent', mods } }
            >
                { children }
            </div>
        );
    }

    render(): ReactElement {
        const { mix, mods } = this.props;
        const { isContentExpanded } = this.state;

        return (
            <article
              block={ isContentExpanded
                  ? 'isContentExpanded isContentExpanded-Visible'
                  : 'isContentExpanded' }
              mix={ mix }
              mods={ mods }
              ref={ this.expandableContentRef }
            >
                { this.renderButton() }
                { this.renderContent() }
            </article>
        );
    }
}

export default ExpandableContentComponent;
